<template>
  <div class="point">
    <div class="header">
      <div class="title">
        <div class="leftFont">
          <h3>{{$t('User.Account.Pages.MyPoint')}}</h3>
          <!-- <p>（可用于兑换优惠劵）</p> -->
        </div>
        <div class="rightFont">
          <p><i class="iconfont icon-wenhao"></i>{{$t('Member.Common.PointExp')}}</p>
        </div>
      </div>
      <div class="pointInfo">
        <h1 class="num">{{ point.point }}</h1>
        <div class="decripe">
          <p class="explain">{{$t('Member.Common.AllPoint')}}</p>
        </div>
      </div>
    </div>

    <div class="list" ref="list">
      <h3 class="title">{{$t('Member.Common.PointDetil')}}</h3>
      <table class="pointlist">
        <thead>
          <tr>
            <th>{{$t('Common.NavTitle.Time')}}</th>
            <th>{{$t('Common.NavTitle.Detail')}} <i class="iconfont icon-jiantou-copy-copy"></i></th>
            <th>{{$t('Common.NavTitle.Explain')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in point.list" :key="item.id">
            <td class="time">{{ item.createtime }}</td>
            <td class="num">
              {{
                item.change_point >= 0
                  ? "+" + item.change_point
                  : item.change_point
              }}
            </td>
            <td class="detil">{{ item.reason }}</td>
          </tr>
        </tbody>
      </table>
      <empty :decripe="$t('Member.Common.NoPoint')" v-if="point.list && point.list.length==0"></empty>


      <!-- 分页 -->
      <div class="pagination">
        <pagination
          :totalPage="point.paginated ? point.paginated.pages : 1"
          v-model="currentPage"
          @changePage="changePage"
        ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "common/pagination/Pagination";
import User from "@/model/User.js";
import Empty from 'common/empty/Empty'
export default {
  name: "MemberPoint",
  data() {
    return {
      //积分数据
      point: {},

      currentPage: 1,
    };
  },
  created() {
    this.getPointList();
  },
  components: {
    Pagination,
    Empty
  },
  methods: {
    //   获取积分列表
    getPointList() {
        let loading
      this.$nextTick(()=>{
           loading = this.$loading(this.$refs.list)
      })
      User.Point.List({
        data: {
          page: this.currentPage,
          limit: 10,
          type: "active",
        },
        succ: (res) => {
          this.point = res;
          loading.close()
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
          loading.close()
        },
        
      });
    },

    //切换页码
    changePage() {
      this.getPointList();
    },
  },
};
</script>
<style lang='scss' scoped>

.point {

  .header {
    padding: 1rem;
    background-color: white;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .leftFont {
        color: var(--text-color-title);
        display: flex;
        align-items: center;
        h3 {
          margin-right: 0.5rem;
          font-size: 1.25rem;
        }
        p {
          color: var(--text-color-default);
          font-size: 0.75rem;
        }
      }

      .rightFont {
        color: var(--text-color-default);
        font-size: 0.75rem;
        p {
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .pointInfo {
      margin-top: 0.75rem;
      display: flex;
      .num {
        font-size: 3rem;
        color: var(--red-color);
      }

      .decripe {
        font-size: 0.75rem;
        color: var(--text-color-default);
        margin-top: 0.75rem;
        margin-left: 0.75rem;
      }
    }
  }

  .list {
      min-height: 400px;
    position: relative;
    margin-top: 1rem;
    background-color: white;
    padding: 1rem;
    font-size: 0.75rem;
    color: var(--text-color-default);
    .title {
      font-size: 1rem;
      color: var(--text-color-title);
      margin-bottom: 1rem;
    }
    .pointlist {
      border-collapse: collapse;
      width: 100%;
      th {
        padding: 0.75rem 0;
        background-color: var(--border-color-default);
      }
      td {
        padding: 0.75rem 0;
        border-bottom: 1px solid var(--border-color-default);
        text-align: center;
      }

      .num {
        color: var(--red-color);
      }
    }

    .pagination{
        display: flex;
        justify-content: flex-end;
        margin-top:1.5rem;
    }
  }
}
</style>